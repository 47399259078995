import { SHA256 } from 'crypto-js'
import Script from 'next/script'
import { useMemo } from 'react'
import { ClientOnly } from '~/elements/ClientOnly/ClientOnly'
import { CostumeSession } from '~/hooks/useSession'

type Props = {
  session: CostumeSession | null
}

export const CioUserScript: React.FC<Props> = ({ session }) => {
  const userId = useMemo(
    () => (session?.user?.email ? SHA256(session.user.email).toString() : undefined),
    [session],
  )

  return (
    <ClientOnly>
      {userId && (
        <Script
          id="cio-user"
          dangerouslySetInnerHTML={{
            __html: `window.cnstrcUserId = "${userId}";`,
          }}
        />
      )}
    </ClientOnly>
  )
}
